import React from 'react';
import ImageSlider from "../../components/ImageSlider";
import CompanySection from "../../components/Sections/CompanySection";
import ProcedureSection from "../../components/Sections/ProcedureSection";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import Plx from 'react-plx';
import SectionTitle from "../../components/SectionTitle";

export let Professionals: any;
export let Procedures: any;
export let Company: any;
export let Login: any;

export const AppContainer = styled.div`
    text-align: center
`;

type Props = {
};

const parallaxDataSlider = [
    {
        start: 100,
        end: 400,
        properties: [
            {
                startValue: 0,
                endValue: 3,
                property: "blur"
            }
        ],
    },
];

const parallaxData = [
    {
        start: 'self',
        duration: 400,
        properties: [
            {
                startValue: 3,
                endValue: 0,
                property: "blur"
            }
        ],
    },
];

const HomePage = (props: Props) => {
    return (
        <AppContainer>
            <Plx
                className='MyAwesomeParallax'
                parallaxData={ parallaxDataSlider }
            >
                <ImageSlider/>
            </Plx>
            <section ref={(section) => { Company = section; }}>
                <Fade bottom>
                    <SectionTitle title="Cristiano Forte" />
                    <Plx
                        className='MyAwesomeParallax'
                        parallaxData={ parallaxData }
                    >
                        <CompanySection/>
                    </Plx>
                </Fade>
            </section>
            <section ref={(section) => { Procedures = section; }}>
                <Fade bottom>
                    <SectionTitle title="Apoio" />
                    <ProcedureSection/>
                </Fade>
            </section>
        </AppContainer>
    );
};

export default HomePage;
