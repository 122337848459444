import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export const NavLink = styled(Nav.Link)`
font-size: large;
font-weight: bolder;
    text-transform: uppercase;
    width: 20%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    @media (max-width: 1150px) {
        width: 100%;
    }
`;

export const LogoImageStyled = styled.img`
    max-width: 50px;
    margin: 0 auto;
`;

export const IconMenuItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const NavBarStyled = styled(Navbar)`
    padding: 0;
    box-shadow: 1px 1px 10px grey;
`;

export const NavDropdownStyled = styled(NavDropdown)`

`;

export const NavStyled = styled(Nav)`
    margin: 0 auto;
    width: 50%;
    @media (max-width: 1150px) {
        width: 100%;
        text-align: center;
    }
`;