import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import lileveLogo from "../../assets/images/drumLogo.png";
import motoLogo from "../../assets/images/motoLogo.png";
import {IconMenuItem, LogoImageStyled, NavBarStyled, NavDropdownStyled, NavLink, NavStyled} from "./styles";
import {Company, Login, Procedures, Professionals} from "../../pages/HomePage";
import NavDropdown from "react-bootstrap/NavDropdown";

const scrollToComponent = require('react-scroll-to-component');

const TopMenu = () => {
    const goToExternalUrl = (url: string) => {
        window.location.href = url;
    };

    return (
        <NavBarStyled bg="light" expand="lg" sticky="top">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <NavStyled>
                    <NavLink>
                        <NavDropdownStyled title="Bateria" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/release.html')}>Release</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/publicacoes.html')}>Publicações</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/discografia.html')}>Discografia</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/premiacoes.html')}>Premiações</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/entrevistas.html')}>Entrevistas</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/equipamentos.html')}>Equipamentos</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/fotos.html')}>Fotos e Vídeos</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/agenda.html')}>Agenda</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/exercicio.html')}>Exercício do Mês</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/musicalizacao.html')}>Musicalização</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/aula-bateria-florianopolis.html')}>Aulas de Bateria</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/biografia.html')}>Biografia</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/contato.html')}>Contato</NavDropdown.Item>
                        </NavDropdownStyled>
                    </NavLink>
                    <IconMenuItem>
                        <LogoImageStyled src={lileveLogo}/>
                    </IconMenuItem>
                    <NavLink
                        href="#">
                        <label>Página inicial</label>
                    </NavLink>
                    <IconMenuItem>
                        <LogoImageStyled src={motoLogo}/>
                    </IconMenuItem>
                    <NavLink>
                        <NavDropdownStyled title="Motociclismo" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/motociclismo.html')}>Motociclismo</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/projeto_1.html')}>Projeto Fortes Ventos I</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/projeto_2.html')}>Projeto Fortes Ventos II</NavDropdown.Item>
                            <NavDropdown.Item onClick={ () => goToExternalUrl('http://www.cristianoforte.com.br/projeto_3.html')}>Projeto Fortes Ventos III</NavDropdown.Item>
                        </NavDropdownStyled>
                    </NavLink>
                    {/*<NavLink*/}
                    {/*    onClick={() => scrollToComponent(Login, { offset: 0, align: 'top', duration: 500, ease:'inExpo'})}*/}
                    {/*    href="#link"><label>Entrar</label></NavLink>*/}
                </NavStyled>
            </Navbar.Collapse>
        </NavBarStyled>
    );
};

export default TopMenu;
