import React from 'react';
import styled from "styled-components";

const LogoWrapper = styled.div`
    display: flex;
    flex-direction: column; 
    padding-top: 1rem;
`;

export default LogoWrapper;
