import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
    center: () => `
        text-align: center;
`,
    right: () => `
        text-align: right;


`,
    left: () => `
        text-align: left;

`,
    justify: () => `
        text-align: justify;
        
`,
    small: () => `
        font-size: small;

`,
    medium: () => `
        font-size: medium;

`,
    large: () => `
        font-size: large;
        
`,
    xlarge: () => `
        font-size: x-large;
        
`,
    xxlarge: () => `
        font-size: xx-large;
        
`,
    xxxlarge: () => `
        font-size: xxx-large;
        
`
};

type Props = {
    modifiers?: string[] | string
}

const Paragraph = styled.p<Props>`
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;
export default Paragraph;
