import React from 'react';
import './App.css';
import {HashRouter} from "react-router-dom";
import Routes from "./routes";


const App: React.FC = () => {
    return (
        <HashRouter>
            <Routes/>
        </HashRouter>
        );
    };

export default App;
