import React from 'react';
import silicredLogo from "../../assets/images/silicred-logo.png";
import owlLogo from "assets/images/owll-logo.png";
import lileveLogo from "../../assets/images/lileve-logo.png";
import styled from "styled-components";
import {FaFacebookSquare, FaInstagram, FaLinkedin} from 'react-icons/fa';
import './styles.css';

type Props = {
};


const FooterLileveContainer = styled.div`
    display: flex;
    box-shadow: 0px 0px 10px #afafaf;
    justify-content: space-evenly;
    background-color: #cacaca; 
    padding: 2rem;
    color: white;
    @media (max-width: 1150px) {
        flex-direction: column;
    }
`;

const FooterCopyrightContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 2rem;
`;

const LileveLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const CenteredContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1150px) {
        flex-direction: row;
    }
    a {
        color: rgba(0,0,0,0.8);
        font-family: confortaa-bold;
    }
`;

const Image = styled.img`
    max-height: 50px;
    max-width: 150px;
    margin: 0 20px;
`;

const SpaceBetweenContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    a {
        ${Image} {
            width: 100%;
            max-height: unset;
            margin: 0;
        }
    }
    @media (max-width: 1150px) {
        flex-direction: column;
        justify-content: space-between;
        a {
            ${Image} {
                width: 100%;
                max-height: unset;
                margin: 2rem 0;
                max-width: 50%;
            }
        }
    }
`;



const FooterContainer = (props: Props) => {
    return (
        <>
            {/*<FooterLileveContainer>*/}
            {/*    <LileveLogoContainer>*/}
            {/*        <img src={lileveLogo} style={{maxWidth: '150px'}} />*/}
            {/*    </LileveLogoContainer>*/}
            {/*    <CenteredContentContainer>*/}
            {/*        <p style={{maxWidth: '400px', textAlign: 'justify'}}>*/}
            {/*            Gostaria de enfatizar que o surgimento do comércio virtual causa impacto indireto na reavaliação dos métodos utilizados na avaliação de resultados.*/}
            {/*        </p>*/}
            {/*    </CenteredContentContainer>*/}
            {/*    <CenteredContentContainer>*/}
            {/*        <a href="https://www.linkedin.com/" target="noreferrer">*/}
            {/*            <FaLinkedin className="iconContainer" color="white"/>*/}
            {/*        </a>*/}
            {/*        <a href="https://www.facebook.com/" target="noreferrer">*/}
            {/*            <FaFacebookSquare className="iconContainer" color="white"/>*/}
            {/*        </a>*/}
            {/*        <a href="https://www.instagram.com/" target="noreferrer">*/}
            {/*            <FaInstagram className="iconContainer" color="white"/>*/}
            {/*        </a>*/}
            {/*    </CenteredContentContainer>*/}
            {/*    <SpaceBetweenContainer>*/}
            {/*        /!*<a href="https://www.embracon.com.br/" target="noreferrer">*!/*/}
            {/*        /!*    <Image src={silicredLogo}/>*!/*/}
            {/*        /!*</a>*!/*/}
            {/*        /!*<a href="https://www.segpro.com.br/" target="noreferrer">*!/*/}
            {/*        /!*    <Image src={owlLogo}/>*!/*/}
            {/*        /!*</a>*!/*/}
            {/*    </SpaceBetweenContainer>*/}
            {/*</FooterLileveContainer>*/}
            <FooterCopyrightContainer>
                <CenteredContentContainer>
                    <p>
                        Desenvolvimento por <a href="https://www.instagram.com/oliveracadu/" target="noreferrer">Cadu Olivera</a>
                    </p>
                </CenteredContentContainer>
            </FooterCopyrightContainer>
        </>
    );
};

export default FooterContainer;
