import React from 'react';
import SectionTitle from "../../SectionTitle";
import face from "../../../assets/images/aquarianlogo.png";
import breast from "../../../assets/images/drummerslogo.png";
import body from "../../../assets/images/ibanezlogo.png";
import notSurgical from "../../../assets/images/oderylogo.png";
import styled from "styled-components";
import {ImageContainer, ProcedureLayer} from './styles';
import Plx from "react-plx";
import Fade from "react-reveal/Fade";

type Props = {
};

const ContentBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const parallaxData = [
    {
        start: 'self',
        duration: 600,
        properties: [
            {
                startValue: 0.5,
                endValue: 1.5,
                property: "scale"
            }
        ],
    },
];

const ProcedureSection = (props: Props) => {
    return (
        <ContentBlock>
                <Plx
                    style={{display: 'flex', maxWidth: '100%', justifyContent: "space-evenly"}}
                    className='MyAwesomeParallax'
                    parallaxData={ parallaxData }>
                    <a href="https://odery.com.br/endorsee/nacionais/cristiano-forte/" target="_blank">
                        <ImageContainer>
                            <img  src={notSurgical} alt=""/>
                        </ImageContainer>
                    </a>
                    <a href="http://www.cibanez.com.br/artista/26/cristiano_forte" target="_blank">
                        <ImageContainer>
                            <img src={body} alt=""/>
                        </ImageContainer>
                    </a>
                    <a href="https://www.facebook.com/drummerscapasparapratos" target="_blank">
                        <ImageContainer>
                            <img  src={breast} alt=""/>
                        </ImageContainer>
                    </a>
                    <a href="http://aquariandrumheads.com.br/site/endorses/" target="_blank">
                        <ImageContainer>
                            <img  src={face} alt=""/>
                        </ImageContainer>
                    </a>
                </Plx>
        </ContentBlock>
    );
};

export default ProcedureSection;
