import React, {useState} from 'react';
import SectionTitle from "../../components/SectionTitle";
import Paragraph from "../../components/Paragraph";
import styled from "styled-components";
import {styled as styledMaterial} from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import RoundButton from "../../components/RoundButton";

type Props = {
};

const InputLabelStyled = styledMaterial(InputLabel)({
});

const OutterContainer = styled.div`
    margin-bottom: 7rem;
    @media (max-width: 1150px) {
        margin-bottom: 2rem;  
    }
`;

const ImageStyled = styled.img`
    max-height: 400px;
    max-width: 300px;
    @media (max-width: 1150px) {
        margin: 0 auto;
        border-radius: 15px;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 1150px) {
        flex-direction: column;
    }
`;

const ContentBlock = styled.div`
    background-color: #cbcad669;
    display: flex;
    box-shadow: 0px 0px 10px #afafaf;
    margin-bottom: 1rem;
    @media (max-width: 1150px) {
        flex-direction: column;
    }
`;

const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    width: 100%;
`;

enum ActiveViewType {
    BASIC_INFO = 'BASIC_INFO',
    TREATMENT = 'TREATMENT',
    FAQ = 'FAQ'
}

const ProceduresPage = (props: Props) => {
    const [activeView, setActiveView] = useState(ActiveViewType.BASIC_INFO);
    const [procedure, setProcedure] = useState({
        id: 1,
        name: 'Cirurgia de Pálpebra',
        shortDesc: 'A nível organizacional, a adoção de políticas descentralizadoras agrega valor ao estabelecimento do processo de comunicação como um todo.',
        fullDesc: 'Do mesmo modo, o novo modelo estrutural aqui preconizado garante a contribuição de um grupo importante na determinação das diretrizes de desenvolvimento para o futuro. É importante questionar o quanto a execução dos pontos do programa nos obriga à análise das direções preferenciais no sentido do progresso. Nunca é demais lembrar o peso e o significado destes problemas, uma vez que a constante divulgação das informações desafia a capacidade de equalização das regras de conduta normativas. As experiências acumuladas demonstram que a crescente influência da mídia promove a alavancagem das diversas correntes de pensamento.',
        image: 'teste',
        basicInfo: {
            title: 'Todavia, a constante divulgação das informações estende o alcance e a importância das regras de conduta normativas. Percebemos, cada vez mais, que a execução dos pontos do programa nos obriga à análise do fluxo de informações. No mundo atual, a adoção de políticas descentralizadoras estimula a padronização dos níveis de motivação departamental.',
            desc: 'Todas estas questões, devidamente ponderadas, levantam dúvidas sobre se o surgimento do comércio virtual pode nos levar a considerar a reestruturação dos modos de operação convencionais. Acima de tudo, é fundamental ressaltar que o consenso sobre a necessidade de qualificação auxilia a preparação e a composição dos procedimentos normalmente adotados. Gostaria de enfatizar que a valorização de fatores subjetivos facilita a criação dos índices pretendidos. Neste sentido, o desenvolvimento contínuo de distintas formas de atuação cumpre um papel essencial na formulação do sistema de formação de quadros que corresponde às necessidades.'
        },
        treatment: 'Ainda assim, existem dúvidas a respeito de como o fenômeno da Internet nos obriga à análise do sistema de participação geral. O empenho em analisar o acompanhamento das preferências de consumo talvez venha a ressaltar a relatividade das posturas dos órgãos dirigentes com relação às suas atribuições. É importante questionar o quanto a determinação clara de objetivos garante a contribuição de um grupo importante na determinação do remanejamento dos quadros funcionais. A certificação de metodologias que nos auxiliam a lidar com a expansão dos mercados mundiais ainda não demonstrou convincentemente que vai participar na mudança dos métodos utilizados na avaliação de resultados. O incentivo ao avanço tecnológico, assim como o aumento do diálogo entre os diferentes setores produtivos oferece uma interessante oportunidade para verificação do retorno esperado a longo prazo.',
        faq: [
            {id: 1,
                question: 'Desta maneira, o novo modelo estrutural aqui preconizado estende o alcance e a importância de alternativas às soluções ortodoxas?',
                answer: 'Por outro lado, o consenso sobre a necessidade de qualificação cumpre um papel essencial na formulação do retorno esperado a longo prazo. É claro que a contínua expansão de nossa atividade garante a contribuição de um grupo importante na determinação dos métodos utilizados na avaliação de resultados. Pensando mais a longo prazo, o julgamento imparcial das eventualidades deve passar por modificações independentemente do sistema de formação de quadros que corresponde às necessidades.'
            },
            {id: 2,
                question: 'Pensando mais a longo prazo, o novo modelo estrutural aqui preconizado agrega valor ao estabelecimento das formas de ação?',
                answer: 'Percebemos, cada vez mais, que o entendimento das metas propostas deve passar por modificações independentemente do retorno esperado a longo prazo. É claro que o início da atividade geral de formação de atitudes agrega valor ao estabelecimento das direções preferenciais no sentido do progresso. Nunca é demais lembrar o peso e o significado destes problemas, uma vez que a estrutura atual da organização aponta para a melhoria dos métodos utilizados na avaliação de resultados. '
            }
        ]
    });

    return (
        <OutterContainer>
            <SectionTitle title={procedure.name} />
            <ContentBlock>
                {/*<ImageStyled src={breast} alt=""/>*/}
                <TextBlock>
                    <Paragraph modifiers={["center", "xlarge"]}>
                        {procedure.shortDesc}
                    </Paragraph>
                    <Paragraph modifiers={["center", "large"]}>
                        {procedure.fullDesc}
                    </Paragraph>
                </TextBlock>
            </ContentBlock>
            <ButtonsContainer>
                <RoundButton
                    variant="primary"
                    size="sm"
                    onClick={ () => setActiveView(ActiveViewType.BASIC_INFO)}>
                    INFORMAÇÕES BÁSICAS
                </RoundButton>
                <RoundButton
                    variant="primary"
                    size="sm"
                    onClick={ () => setActiveView(ActiveViewType.TREATMENT)}>
                    SOBRE O TRATAMENTO
                </RoundButton>
                <RoundButton
                    variant="primary"
                    size="sm"
                    onClick={ () => setActiveView(ActiveViewType.FAQ)}>
                    PERGUNTAS FREQUENTES
                </RoundButton>
            </ButtonsContainer>
            <>
                {
                    {
                        'BASIC_INFO' : <>
                            <div>{procedure.basicInfo.title}</div>
                            <div>{procedure.basicInfo.desc}</div>
                        </>,
                        'TREATMENT' : <>
                            <div>{procedure.treatment}</div>
                        </>,
                        'FAQ' : <>
                            <div>{ procedure.faq.map( (f: any) => (
                                <label key={f.id}>{f.question}</label>
                            ))}</div>
                        </>,
                    }[activeView]
                }
            </>
        </OutterContainer>
    );
};

export default ProceduresPage;
