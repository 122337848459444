import React from 'react';
import company from "../../../assets/images/drums.png";
import styled from "styled-components";

type Props = {
    bgimage?: any;
};

const ContentBlock = styled.div<Props>`
    background: linear-gradient(45deg, #afafaf, #fff);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    box-shadow: 0px 0px 12px #5f5f5f;
    @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
    }
`;

const TextBlock = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 3%;
`;

const ImgStyled = styled.img`
    max-width: 450px;
    max-height: 350px;
    @media (max-width: 900px) {
        max-width: 360px;
    }
`;

const ReleaseText = styled.p`
    font-family: confortaa-regular;
    color: rgba(0,0,0,0.5);
    font-size: medium;
    a {
        color: rgba(0,0,0,0.8);
        font-family: confortaa-bold;
    }
`;

const CompanySection = (props: Props) => {
    return (
        <div>
            <ContentBlock>
                <ImgStyled src={company}/>
                <TextBlock>
                    {/*<Paragraph modifiers={["left", "xxxlarge"]}>*/}
                    {/*    Web Site sobre a trajetória musical de Cristiano Forte*/}
                    {/*</Paragraph>*/}
                    <ReleaseText>
                        Baterista profissional desde 1990, educador musical e pedagogo, com pós-graduação em Música: Práticas Sociais. Em sua trajetória, acumulam-se premiações em concursos nacionais de bateristas; Prêmio "Homem Contemporâneo", pela contribuição cultural para a cidade de Criciúma (2010); Prêmio "Referências", homenagem no evento Bateras do Sul, pelos serviços prestados a bateria no sul do país (2019); publicações em revistas de circulação nacional e portais internacionais como da revista Modern Drummer (USA). Em sua discografia, há a participação em gravações de mais 60 trabalhos. Atualmente possui o projeto de gravações de bateria on-line oferecido pelo seu site: <a href="http://www.gravacaodebateriaonline.com.br">www.gravacaodebateriaonline.com.br</a>. Há cerca 10 anos vem realizando apresentações musicais em mais de 250 escolas públicas, nas quais já participaram mais de 25.000 alunos de diferentes faixas etárias. Realizou viagens de moto, entre elas o Projeto "FORTES" Ventos, no qual em suas três etapas foram percorridos mais de 30.000 km em estradas do Brasil, Uruguai, Argentina e Chile, na maioria pela Patagônia e levando uma mini bateria na bagagem. Possui, desde 2003, o site <a href="http://www.cristianoforte.com.br">www.cristianoforte.com.br</a>.
                    </ReleaseText>
                    {/*<Paragraph modifiers="right">*/}
                    {/*    <RoundButton variant="primary" size="sm">*/}
                    {/*        SAIBA MAIS*/}
                    {/*    </RoundButton>*/}
                    {/*</Paragraph>*/}
                </TextBlock>
            </ContentBlock>
        </div>
    );
};

export default CompanySection;
