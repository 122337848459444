import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ProfessionalsSearch from "./pages/ProfessionalsSearch";
import TopMenu from "./components/TopMenu";
import HomePage, {AppContainer} from "./pages/HomePage";
import FooterContainer from "./components/FooterContainer";
import ProceduresPage from "./pages/Procedures";
import Fade from "react-reveal/Fade";

export let Home: any;

const Routes: React.FC = () => {
    return (
        <>
            <section ref={(section) => { Home = section; }}>
                <TopMenu/>
            </section>
            <Switch>
                <Route exact path="/search-professionals" component={ProfessionalsSearch}/>
                <Route exact path={`/procedure/:id`} component={ProceduresPage}/>
                <Route path="/" component={HomePage}/>
            </Switch>
            <Fade bottom>
                <FooterContainer />
            </Fade>
        </>
    );
};

export default Routes;
