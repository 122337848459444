import React from 'react';
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const RoundButton = styled(Button)`
    border-radius: 35px;
    font-family: confortaa-regular;
    margin: 0.5rem 0;
    text-align: center;
    text-transform: uppercase;
    background-color: #7e778c;
    padding: 0.3rem 2.5rem;
    border: none;
    :hover, :focus, :active {
        background-color: #716b7d;
    }
`;

export default RoundButton;
