import React from 'react';
import flower from './../../assets/images/drumLogo.png';

type Props = {
    title: string
};

const SectionTitle = (props: Props) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', padding: '5rem 1rem 3rem'}}>
            {/*<img style={{alignSelf: 'center', width: '50px'}} src={flower}/>*/}
            <h2 style={{color: 'darkgray', textTransform: 'uppercase', fontFamily: 'confortaa-bold', textAlign: 'center'}}>{props.title}</h2>
        </div>
    );
};

export default SectionTitle;
