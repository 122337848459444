import React from 'react';
import flowerWhite from "./../../assets/images/drumLogo.png";
import wingsLeft from "./../../assets/images/wingsCutLeft.png";
import wingsRight from "./../../assets/images/wingsCutRight.png";
import styled from "styled-components";
import LogoWrapper from "../LogoWrapper";

const reactSlideshow = require('react-slideshow-image');

type Props = {
};

const TextLayer = styled.div`
    display: flex; 
    flex-direction: column; 
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    justify-content: space-between;
    width: 100%;
`;

const ImageLayer = styled.img`
    position: absolute; 
    top: 20%;
    z-index: 1;
    @media (max-width: 1150px) {
        width: 25%;
    }
`;

const LogoImage = styled.img`
    align-self: center; 
    width: 80px;
`;

const PageTitle = styled.h2`
    text-shadow: black 3px 4px 3px; 
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bolder;
    @media (max-width: 900px) {
        font-size: x-large;
    }
`;

const ImageSlider = (props: Props) => {
    const fadeProperties = {
        duration: 3000,
        transitionDuration: 500,
        infinite: true,
        arrows: false,
        indicators: false,
    };

    return (
        <div className="slide-container">
            <reactSlideshow.Fade {...fadeProperties}>
                <div className="each-fade">
                    <div className="image-container image-container-1">
                    </div>
                </div>
                <div className="each-fade">
                    <div className="image-container image-container-3">
                    </div>
                </div>
                <div className="each-fade">
                    <div className="image-container image-container-2">
                    </div>
                </div>
                <div className="each-fade">
                    <div className="image-container image-container-4">
                    </div>
                </div>
            </reactSlideshow.Fade>
            <TextLayer>
                <LogoWrapper>
                    {/*<LogoImage src={flowerWhite}/>*/}
                </LogoWrapper>
                <PageTitle>Bem-vindo à página oficial de Cristiano Forte</PageTitle>
                <h1 style={{opacity: 0}}>.</h1>
            </TextLayer>
            {/*<ImageLayer style={{ left: 0 }} src={wingsLeft} />*/}
            {/*<ImageLayer style={{ right: 0 }} src={wingsRight} />*/}
        </div>
    );
};

export default ImageSlider;
