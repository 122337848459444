import styled from 'styled-components';

export const ImageContainer = styled.div`
    max-width: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 1rem;
    img {
        width: 100%;
    }
`;

export const ProcedureLayer = styled.div`
    height: 100%;
    width: 100%;
    background: rgba(97, 86, 136, 0.5);
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease;
    :hover {
        opacity: 1;
    }
    p {
        font-size: x-large;
    }
    * {
        color: white;
        text-transform: uppercase;
    }
`;
