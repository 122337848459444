import React from 'react';
import {AnchorPhone, ContactContainer, FaPhoneStyled, LabelPhone, PhoneContainer} from "./styles";
import {FaFacebookSquare, FaInstagram, FaLinkedin, FaPhone} from "react-icons/fa";
import {Doctor} from "../../interfaces/doctor";

type Props = {
    doctor?: Doctor
}

const ProfessionalContactContainer = (props: Props) => {
    return (
        <ContactContainer>
            <AnchorPhone>
                <PhoneContainer>
                    <LabelPhone>
                        {props.doctor?.phone}
                    </LabelPhone>
                    <FaPhoneStyled className="iconContainer" color="gray"/>
                </PhoneContainer>
            </AnchorPhone>
            <a href="https://www.linkedin.com/" target="noreferrer">
                <FaLinkedin className="iconContainer" color="white"/>
            </a>
            <a href="https://www.facebook.com/" target="noreferrer">
                <FaFacebookSquare className="iconContainer" color="white"/>
            </a>
            <a href="https://www.instagram.com/" target="noreferrer">
                <FaInstagram className="iconContainer" color="white"/>
            </a>
        </ContactContainer>
    );
};

export default ProfessionalContactContainer;
