import React, {useCallback, useEffect, useState} from 'react';
import SectionTitle from "../../components/SectionTitle";
import Paragraph from "../../components/Paragraph";
import RoundButton from "../../components/RoundButton";
import styled from "styled-components";
import {styled as styledMaterial} from '@material-ui/core/styles';
import {InputBase} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {Doctor} from "../../interfaces/doctor";
import ProfessionalContactContainer from "../../components/ProfessionalContactContainer";

type Props = {
};

const SelectInput = styledMaterial(InputBase)({
    background: 'white',
    borderRadius: '19px'
});

const SelectStyled = styledMaterial(Select)({
    background: 'white',
    width: '100%',
    borderRadius: '20px'
});

const MenuSelectStyled = styledMaterial(MenuItem)({
    background: 'white',
    borderRadius: '21px'
});

const InputLabelStyled = styledMaterial(InputLabel)({
});

const ContentBlock = styled.div`
    background-color: #cbcad669;
    display: flex;
    box-shadow: 0px 0px 10px #afafaf;
    margin-bottom: 7rem;
    @media (max-width: 1150px) {
        flex-direction: column;
        margin-bottom: 2rem;  
    }
`;

const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    width: 100%;
`;

const ProfessionalItem = styled.div`
    display: flex;
    background: #eae9ee;
    width: 60%;
    margin: 0 auto 4rem;
    border-radius: 10px;
`;

const ProfessionalImage = styled.img`
    
`;

const ProfessionalData = styled.div`
    position: relative;
`;

const ProfessionalsSearch = (props: Props) => {
    const [dado, setDado] = useState(" ");
    const [professionalList, setProfessionalList] = useState<Doctor[]>([
        {
            id: 1,
            name: 'João Filho',
            desc: 'No mundo atual, a percepção das dificuldades garante a contribuição de um grupo importante na determinação dos conhecimentos estratégicos para atingir a excelência.',
            crm: '12.449',
            specialty: 'Cirurgião Plástico',
            qualications: [
                'teste',
                'teste2'
            ],
            linkedin: 'https://www.linkedin.com/in/',
            instagram: 'https://instagram.com/',
            facebook: 'https://facebook.com',
            phone: '48991456901',
            address: {
                id: 1,
                city: 'Florianópolis',
                state: 'Santa Catarina'
            }
        }
    ]);

    const handleChange = useCallback((e: any) => {
        setDado(e.target.value);
    }, [setDado]);

    useEffect( () => {
        console.log(dado);
    }, [dado]);

    return (
        <>
            <SectionTitle title="Profissionais" />
            <ContentBlock>
                <TextBlock>
                    <Paragraph modifiers={["center", "xlarge"]}>
                        Para melhorar sua busca, selecione seu Estado e Cidade
                    </Paragraph>
                    <div>
                        <div style={{width: '50%', margin: '0 auto', textAlign: 'center'}}>
                            {/*<InputLabelStyled>Estado</InputLabelStyled>*/}
                            <SelectStyled
                                value={dado}
                                onChange={ (e) => handleChange(e) }
                                input={<SelectInput />} >
                                <MenuSelectStyled value=" ">
                                    <em>Estado</em>
                                </MenuSelectStyled>
                                <MenuSelectStyled value={10}>Santa Catarina</MenuSelectStyled>
                                <MenuSelectStyled value={20}>Paraná</MenuSelectStyled>
                            </SelectStyled>
                        </div>
                        <div style={{width: '50%', margin: '1rem auto', textAlign: 'center'}}>
                            {/*<InputLabelStyled>Cidade</InputLabelStyled>*/}
                            <SelectStyled
                                value={dado}
                                onChange={ (e) => handleChange(e) }
                                input={<SelectInput />} >
                                <MenuSelectStyled value=" ">
                                    <em>Cidade</em>
                                </MenuSelectStyled>
                                <MenuSelectStyled value={10}>Florianópolis</MenuSelectStyled>
                                <MenuSelectStyled value={20}>Curitiba</MenuSelectStyled>
                            </SelectStyled>
                        </div>
                    </div>
                    <Paragraph modifiers="center">
                        <RoundButton variant="primary" size="sm">
                            BUSCAR
                        </RoundButton>
                    </Paragraph>
                </TextBlock>
            </ContentBlock>
            {professionalList.map((professional: any) => (
                <ProfessionalItem>
                    {/*<ProfessionalImage src={doctor}/>*/}
                    <ProfessionalData>
                        <ProfessionalContactContainer doctor={professional}/>
                        <p>{professional.name}</p>
                        <p>{professional.specialty}</p>
                        <p>{professional.desc}</p>
                        <div>{professional.qualications.map( (q: any, i: number) => (
                            <p key={i}>{q}</p>
                        ))}</div>
                    </ProfessionalData>
                </ProfessionalItem>
                ))
            }
        </>
    );
};

export default ProfessionalsSearch;
