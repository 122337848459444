import styled from "styled-components";
import {FaPhone} from "react-icons/fa";

export const ContactContainer = styled.div`
    display: flex;
    position: absolute;
    top: 10;
    right: 0;
    margin-top: 1rem;
`;

export const PhoneContainer = styled.div`
    background: white;
    padding-right: 2.5rem;
    position: relative;
    border-radius: 10px;
`;

export const FaPhoneStyled = styled(FaPhone)`
    color: gray;
    position: absolute;
    top: 0;
    right: 0;
`;

export const LabelPhone = styled.div`
    vertical-align: sub;
`;

export const AnchorPhone = styled.a`
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.5rem;
`;
